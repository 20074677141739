import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PriceTemplate from '../components/price/PriceTemplate';

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <PriceTemplate table={post.frontmatter.table} />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query PricePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        table {
          plans {
            name
          }
          prices {
            name
            Free
            Venture
            Business
            Unlimited
            help
          }
          features {
            name
            Free
            Venture
            Business
            Unlimited
            help
          }
        }
      }
    }
  }
`;

export default AboutPage;
