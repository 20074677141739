import React from 'react';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

import Proposal from './Proposal';

const PricePageTemplate = ({ table }) => {
  const { plans, features, prices } = table;
  const color = ['#66b6ff', '#d6bc977', '#1b5ba7', '#bf700b'];

  return (
    <div className="price_page_template">
      <Proposal />
      <div className="container">
        <div className="section">
          <div className="table-container">
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <th style={{ width: '500px' }}>&nbsp;</th>
                  {map(plans, (plan, index) => (
                    <th
                      style={{ width: '150px', textAlign: 'center' }}
                      key={plan.name}
                    >
                      <span style={{ color: color[index] }}>{plan.name}</span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {map(prices, (price, index) => (
                  <tr key={index}>
                    <>
                      {price.help ? (
                        <td>
                          {price.name}
                          <div className="tooltip">
                            <span className="icon">
                              <i className="fas fa-question-circle"></i>
                            </span>
                            <span className="tooltip-text">{price.help}</span>
                          </div>
                        </td>
                      ) : (
                        <td>{price.name}</td>
                      )}
                      <td style={{ textAlign: 'center' }}>{price.Free}</td>
                      <td style={{ textAlign: 'center' }}>{price.Venture}</td>
                      {price.Business.length > 1 ? (
                        <td style={{ textAlign: 'center' }}>
                          <div className="select is-small">
                            <select>
                              {map(price.Business, (Business, index) => (
                                <option value={Business} key={index}>
                                  {Business}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                      ) : (
                        <td style={{ textAlign: 'center' }}>
                          {price.Business}
                        </td>
                      )}
                      {price.Unlimited.length > 1 ? (
                        <td style={{ textAlign: 'center' }}>
                          <div className="select is-small">
                            <select>
                              {map(price.Unlimited, (b, index) => (
                                <option value={b} key={index}>
                                  {b}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                      ) : (
                        <td style={{ textAlign: 'center' }}>
                          {price.Unlimited}
                        </td>
                      )}
                    </>
                  </tr>
                ))}
                <tr>
                  <td colSpan={5}>&nbsp;</td>
                </tr>
                {map(features, (feature, index) => (
                  <tr key={index}>
                    {feature.Free === false &&
                    feature.Venture === false &&
                    feature.Business === false &&
                    feature.Unlimited === false ? (
                      <th colSpan={5}>{feature.name}</th>
                    ) : (
                      <>
                        {feature.help ? (
                          <td>
                            {feature.name}
                            <div className="tooltip">
                              <span className="icon">
                                <i className="fas fa-question-circle"></i>
                              </span>
                              <span className="tooltip-text">
                                {feature.help}
                              </span>
                            </div>
                          </td>
                        ) : (
                          <td>{feature.name}</td>
                        )}
                        <td style={{ textAlign: 'center' }}>
                          {feature.Free ? (
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="has-text-success"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faXmark}
                              className="has-text-grey"
                            />
                          )}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {feature.Venture ? (
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="has-text-success"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faXmark}
                              className="has-text-grey"
                            />
                          )}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {feature.Business ? (
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="has-text-success"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faXmark}
                              className="has-text-grey"
                            />
                          )}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {feature.Unlimited ? (
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="has-text-success"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faXmark}
                              className="has-text-grey"
                            />
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

PricePageTemplate.propTypes = {
  table: PropTypes.shape({
    plans: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        Free: PropTypes.string,
        Venture: PropTypes.string,
        Business: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.number),
        ]),
        Unlimited: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.number),
        ]),
        help: PropTypes.string,
      }),
    ),
    features: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        Free: PropTypes.bool,
        Venture: PropTypes.bool,
        Business: PropTypes.bool,
        Unlimited: PropTypes.bool,
        help: PropTypes.string,
      }),
    ),
  }),
};

export default PricePageTemplate;
