import React from 'react';

const Proposal = () => {
  return (
    <div className="container">
      <div className="section is-flex-direction-column">
        <div className="columns is-mobile">
          <div className="column is-half is-offset-one-quarter content is-medium">
            <h1 className="has-text-centered">Ціни</h1>
            <p className="has-text-centered">
              Вартість сайту інтернет-магазину складається з<br /> щомісячної
              абонплати
            </p>
          </div>
        </div>
        <div className="section">
          <div className="columns">
            <div className="column">
              <div className="is-size-4">Стартовий платіж</div>
              <div className="is-size-6">
                Запуск сайту, налаштування дизайну,
                <br />
                імпорт товарів в каталог
              </div>
            </div>
            <div className="column">
              <del className="is-size-4">10 000 грн</del>
              <div className="is-size-4">
                0 <span className="is-size-6">грн</span>
              </div>
              <ul>
                <li>Готовий дизайн з галереї</li>
              </ul>
            </div>

            <div className="column">
              <del className="is-size-4">15 000 грн</del>
              <div className="is-size-4">
                5 000 <span className="is-size-6">грн</span>
              </div>
              <ul>
                <li>Послуга з розширеного налаштування дизайну</li>
              </ul>
            </div>

            <div className="column">
              <del className="is-size-4">25 000 грн</del>
              <div className="is-size-4">
                5 000 <span className="is-size-6">грн</span>
              </div>

              <ul>
                <li>
                  Послуга з налаштування
                  <br /> <a href="/ua/Unlimited/">Unlimited-порталу</a>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <div className="is-flex is-align-content-center">
              <h1 className="is-size-4 pr-3">Абонплата</h1>
              <div className="is-flex is-align-items-center">
                <label
                  htmlFor="switcher-left-side"
                  className="is-flex is-align-content-center p-1"
                >
                  щомісячно
                </label>
                <input
                  id="switcher-left-side"
                  name="switcher"
                  type="radio"
                  value="price-normal"
                  className="m-1"
                />
                <input
                  defaultChecked
                  id="switcher-right-side"
                  name="switcher"
                  type="radio"
                  value="price-discounted"
                  className="m-1"
                />
                <label
                  htmlFor="switcher-right-side"
                  className="is-flex is-align-content-center p-1"
                >
                  оплата за рік -20%
                </label>
              </div>
            </div>

            <div>
              Доступ в кабінет, розміщення на сервері, оновлення та нові
              функції, підтримка та моніторинг
            </div>

            <div className="columns pt-5">
              <div className="column">
                <p className="is-size-4">
                  <span style={{ color: '#66B6FF' }}>Basic</span>
                </p>

                <p className="is-flex pb-1">
                  <span className="is-size-3 pr-1">240</span>
                  <span className="is-size-7 is-flex is-align-items-center">
                    грн <br />
                    на місяць
                  </span>
                </p>

                <ul>
                  <li style={{ fontSize: '11px', color: '#777' }}>
                    оплата тільки на рік
                  </li>
                  <li>1 000 товарів</li>
                  <li>Мобільна версія</li>
                  <li>Сервіси доставки та оплати</li>
                  <li>Знижки та акції</li>
                  <li>Мультимовність та валюти</li>
                  <li>
                    Варіанти товарів, налаштування фільтрів , імпорт та експорт
                    товарів
                  </li>
                  <li>Аналітика, SEO</li>
                  <li>
                    <a href="/ua/functions/">
                      І ще 150+ функцій для сучасного інтернет-магазину
                    </a>
                  </li>
                </ul>
              </div>

              <div className="column">
                <p className="is-size-4">
                  <span style={{ color: '#6BC977' }}>Standard</span>
                </p>

                <p className="is-flex pb-1">
                  <span className="is-size-3 pr-1">680</span>
                  <span className="is-size-7 is-flex is-align-items-center">
                    грн <br />
                    на місяць
                  </span>
                </p>

                <ul>
                  <li>20 000 товарів</li>
                  <li>Всі функції тарифу Basic</li>
                  <li>Звіти</li>
                  <li>Rozetka, Kasta, Google Shopping, Facebook feed</li>
                  <li>
                    «Оплата частинами» ПриватБанку, «Покупка частинами»
                    від&nbsp;monobank
                  </li>
                  <li>Кратність товару, мінімальне замовлення</li>
                  <li>Електронні товари</li>
                </ul>
              </div>

              <div className="column">
                <p className="is-size-4">
                  <span style={{ color: '#1B5BA7' }}>Pro</span>
                </p>

                <p className="is-flex pb-1">
                  <span className="is-size-3 pr-1">1440</span>
                  <span className="is-size-7 is-flex is-align-items-center">
                    грн <br />
                    на місяць
                  </span>
                </p>

                <ul>
                  <li>50 000 товарів</li>
                  <li>Всі функції тарифу Standard</li>
                  <li>Оптові ціни за кількість</li>
                  <li>API</li>
                </ul>
              </div>

              <div className="column">
                <p className="is-size-4">
                  <span style={{ color: '#bf700b' }}>Unlimited</span>
                </p>

                <p className="is-flex pb-1">
                  <span className="is-size-3 pr-1">2800</span>
                  <span className="is-size-7 is-flex is-align-items-center">
                    грн <br />
                    на місяць
                  </span>
                </p>

                <ul>
                  <li>50 000 товарів</li>
                  <li>Всі функції тарифу PRO</li>
                  <li>Типи цін та групи покупців</li>
                  <li>Кабінет дилера</li>
                  <li>Прайс для дилера</li>
                  <li>Unlimited API</li>
                  <li>
                    <a href="/ua/Unlimited/">
                      Детальніше про гуртові можливості
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <span className="prices__note">Всі ціни вказані без ПДВ</span>
        </div>
      </div>
    </div>
  );
};

export default Proposal;
